import React from 'react';

const Location = () => {
  return (
    <div>

        <h3 className='panel-title'>Location</h3>
        <p>
          <a
            href='https://maps.google.com/?q=5869+Hollister+Ave,+Goleta,+CA+93117'
            className='out-link'
          >
            <address>5869 Hollister Ave, Goleta, CA 93117</address>
          </a>
        </p>
        <p>
          <a
            href='https://maps.google.com/?q=5869+Hollister+Ave,+Goleta,+CA+93117'
            className='out-link'
          >
            <img
              className='img-responsive img-rounded'
              src='/img/noodlecity-map.png'
              alt='Map of 5869 Hollister Ave, Goleta, CA 93117'
            />
          </a>
        </p>
      
    </div>
  );
};

export default Location;
