import React from 'react';

const MenuImg = ({ number }) => {
  return (
    <img
      src={`/img/menu-${number}.png`}
      width='412'
      height='768'
      className='img-fluid rounded mb-4'
      alt={`Menu page ${number}`}
    />
  );
};

export default MenuImg;
