import React from 'react';

const Footer = () => {
  return (
    <footer className='my-2'>
      Last update: Nov 2022. This website is not affiliated with Noodle City and everything could be wrong. We are just customers that wanted the menu online, hope you find it useful.
    </footer>
  );
};

export default Footer;
