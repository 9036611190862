import React from 'react';
import MenuImg from './MenuImg';

const Menu = () => {
  return (
    <div>
  
        <h3 className='card-title'>Menu</h3>
        <div className='row'>
          <div className='col-sm-6'>
            <MenuImg number={1} />
          </div>
          <div className='col-sm-6'>
            <MenuImg number={2} />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6'>
            <MenuImg number={3} />
          </div>
          <div className='col-sm-6'>
            <MenuImg number={4} />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6'>
            <MenuImg number={5} />
          </div>
          <div className='col-sm-6'>
            <MenuImg number={6} />
          </div>
        </div>
    </div>
  );
};

export default Menu;
