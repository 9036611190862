import React from 'react';
import './App.css';
import Menu from './Menu';
import Hours from './Hours';
import Phone from './Phone';
import Location from './Location';
import Reviews from './Reviews';
import Footer from './Footer';

const App = () => {
  return (
    <>
      <div className='container'>
        <header>
          <h1>Noodle City</h1>
        </header>

        <p className='lead'>Vietnamese Restaurant in Old Town Goleta</p>

        <ul className='nav nav-tabs mb-3' role='tablist'>
          <li className='nav-item'>
            <a
              className='nav-link active'
              id='menu-tab'
              data-toggle='pill'
              href='#menu'
              role='tab'
              aria-controls='menu'
              aria-selected='true'
            >
              Menu
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='info-tab'
              data-toggle='pill'
              href='#info'
              role='tab'
              aria-controls='info'
              aria-selected='false'
            >
              Info
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              id='reviews-tab'
              data-toggle='pill'
              href='#reviews'
              role='tab'
              aria-controls='reviews'
              aria-selected='false'
            >
              Reviews
            </a>
          </li>
        </ul>
        <div className='tab-content'>
          <div
            className='tab-pane fade show active'
            id='menu'
            role='tabpanel'
            aria-labelledby='menu-tab'
          >
            <Menu />
          </div>
          <div
            className='tab-pane fade'
            id='info'
            role='tabpanel'
            aria-labelledby='info-tab'
          >
            <div className='row'>
              <div className='col-sm-6'>
                <Hours />
              </div>
              <div className='col-sm-6'>
                <Phone />
                <Location />
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='reviews'
            role='tabpanel'
            aria-labelledby='reviews-tab'
          >
            <Reviews />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default App;
