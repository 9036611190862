import React from 'react';

const Reviews = () => {
  return (
    <div>
      <h3>Reviews</h3>

      <p>
        <a
          href='http://www.santabarbara.com/dining/review_read.asp?pk_restaurant=1491'
          className='out-link'
        >
          Santa Barbara Restaurant Guide
        </a>
      </p>
      <p>
        <a
          href='https://www.tripadvisor.com/Restaurant_Review-g32438-d3187198-Reviews-Noodle_City-Goleta_California.html'
          className='out-link'
        >
          TripAdvisor
        </a>
      </p>
      <p>
        <a
          href='https://www.yelp.com/biz/noodle-city-goleta'
          className='out-link'
        >
          Yelp
        </a>
      </p>
      <p>
        <a
          href='https://www.zomato.com/santa-barbara-ca/noodle-city-goleta/reviews'
          className='out-link'
        >
          Zomato
        </a>
      </p>
    </div>
  );
};

export default Reviews;
