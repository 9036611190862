import React from 'react';

const Phone = () => {
  return (
    <div>

      <h3 className='card-title'>Phone</h3>
        <p>
          <a href='tel:805-683-6818' className='out-link'>
            805-683-6818
          </a>
        </p>
   
    </div>
  );
};

export default Phone;
