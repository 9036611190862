import React from 'react';

const Hours = () => {
  return (
    <div>
      <h3>Hours</h3>
      <p>Open everyday except Sunday.</p>
      <hr />

      <p>Sunday : Closed</p>
      <p>Monday : 10AM - 9PM</p>
      <p>Tuesday : 10AM - 9PM</p>
      <p>Wednesday : 10AM - 9PM</p>
      <p>Thursday : 10AM - 9PM</p>
      <p>Friday : 10AM - 9PM</p>
      <p>Saturday : 10AM - 9PM</p>
    </div>
  );
};

export default Hours;
